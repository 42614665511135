import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

// import UserService from '../services/UserService'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'home',
    component: HomeView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, _from, next) => {
  console.log('Router before each')
  next()
})

export default router
